<template>
 <div class="relative tiktok-wrapper">
    <div class="prediction-bg">
        <div class="tiktok-bg__cover"></div>
        <!-- <img v-if="isMobile" class="w-100" src="../../../../static/images/worldcup2022/mobile_background.png"> -->
        <img class="img-cover" src="../../../../static/images/tiktok/background.png">
    </div>
    <div class="container display-flex flex-col has-space">
        <div class="mobile-header  mb-05rem" v-if="isMobile">
                <MainLogo></MainLogo>
                <div class="tc font-mbold mobile-header__center">{{ $t('EVENT') }}</div>
                <div  class="mobile-header__right">
                </div>

        </div>
        <div class="prediciton-banner__wrapper" >
            <Banner 
                :type = 2
                :contestInfo="contestInfo"
            />
        </div>

        <Countdown
            :contestInfo="contestInfo"
            @emitCountdownEvent="receiveCountdownEvent"
        />

        <div class="display-flex-center flex-col mb-1rem">
            <div class="display-flex-center mb-05rem">
                <img class="tiktok-icon" src="../../../../static/images/icons/icon_tiktok.png">
                <h2 class="tiktok-title">{{ $t('TIK_TOK_VIDEO_CHALLANGE') }}</h2>
            </div>
            <p class="tiktok-desc">{{ $t('TIK_TOK_EVENT_DESC')  }}</p>
        </div>

        <div class="tiktok-video__wrapper">
            <!-- <img class="tiktok-video__img" src="../../../../static/images/tiktok/tiktok_default_thumbnail.png"> -->
            <video class="w-100 h-100" 
                :poster="isMobile 
                ? require('../../../../static/images/tiktok/tiktok_default_thumbnail_no_play_btn.png')
                : require('../../../../static/images/tiktok/tiktok_default_thumbnail.png') " 
                :src="`${s3ImgUrl}/contest/TIKTOK2022/th/tiktok_video.mp4`" 
                controls>
            </video>
        </div>
        
        <div class="tiktok-participate__wrapper">
            <div class="tiktok-participate__header">{{ $t('HOW_TO_PARTICIPATE')}}?</div>
            <div class="tiktok-participate__body">
                <div class="tiktok-participate__body-content" v-html="contestInfo.question">
                </div>
            </div>
        </div>

        <div class="display-flex-center tiktok-tnc__wrapper">
            <div class="event-team__term_condition btn--underline" @click="showModalTermCondition()">{{ $t('TERMS_&_CONDITIONS') }}</div>
        </div>
         
    </div>
</div>

<!-- term and condition desktop-->
<ModalPopup ref="modalPopupRef" :modalSize="'contest-tnc__height'">
    <h3 class="tc mt-15rem mb-15rem">{{ $t('TERMS_&_CONDITIONS') }}</h3>
    <perfect-scrollbar class="perfect-scrollball__medium">
        <div class="term-body" v-html="contestInfo.tnc"></div>
    </perfect-scrollbar>
</ModalPopup>

<!-- term and condition mobile-->
<MobileSlide ref="mobileSlideRef" :title = "'TERMS_&_CONDITIONS'">
    <div class="pb-1rem term-body"  v-html="contestInfo.tnc"></div>
</MobileSlide>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination,Navigation,Autoplay} from 'swiper/core';
SwiperCore.use([Pagination,Navigation,Autoplay]);

import { mapGetters,mapActions } from 'vuex'

import config from '@/js/config.js'

import ModalPopup from '@/components/modal/ModalPopup.vue'
import MobileSlide from '@/components/modal/MobileSlide.vue'
import MainLogo from "@/components/indicator/MainLogo.vue";

import Banner from '@/components/event/Banner.vue'
import Countdown from '@/components/event/Countdown.vue'
import Title from '@/components/event/Title.vue'


export default {
    components: {
        ModalPopup,
        MobileSlide,
        Banner,
        Countdown,
        Title,
        MainLogo,
        Swiper,
        SwiperSlide,      
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,

            currentPathName: '',

            contestId: null,

            contestInfo: {}, 
        }
    },
    watch:{
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
                //this.init(); 
            }
        },
        currentLanguageObj: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.displayName === "English") {
                    //this.init();
                }
            }
        },
        currentCountryObj: {
            deep: true,
            handler(prev, nesw) {
              //this.init(); 
            }
        },
         userSwitchCountryLang: {
            deep: true,
            handler(prev, nesw) {
                this.$router.push(`/${this.currentLanguageObj.displayLocale}/event`);  
                //this.init();
            }
        }
   },
    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin',
                'currentCountryObj',
                'userSwitchCountryLang'
           ]),
    },

    mounted() {
        this.commonVerifyLogin();
        this.init();
    },
    methods: {
        ...mapActions([
            "getContestInfo",
            "handleContestEndEvent",
            "handleEventEnded",
            "getCountryCodeByIp",
            "currentCountryChangeEvent",
            "commonVerifyLogin",
        ]),

        async init() {
            //get contest id
            this.contestId = this.$route.params.contestId;
            
            if(!this.isLogin && !this.userCountryCode) {
                let result = await this.getCountryCodeByIp();
                if (result.message === "Success") {
                    this.currentCountryChangeEvent(result.result.countryCode);
                }
            }
            this.getContestInfoData();
        },
        
        async getContestInfoData() {
            let params = {
               contestId: this.contestId,
               language: this.currentLanguageObj.locale,
               lang: this.currentLanguageObj.apiParam,
            }

            const result = await this.getContestInfo(params);

            //if there is no contest for current language, will redirect to event (AS-1432) homepage
            if (result.result === null) {       
                this.$router.push(`/${this.currentLanguageObj.displayLocale}/event`);
                return;
            } else {
                this.contestInfo = result.result;
            }

            if (this.contestInfo.timeType === 1) {
                //past event
                this.handleContestEndEvent(true); 
            } else {
                this.handleContestEndEvent(false); 
            }
 

        },
        receiveCountdownEvent() {
            this.init();
        },

        //show modal pop up term & condition
        showModalTermCondition() {
            if (this.isMobile) {
                this.$refs.mobileSlideRef.handleOpen();
            } else {
                 this.$refs.modalPopupRef.handleOpen();
            }
        },
    },
}
</script>


<style scoped>

    .prediction-bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom:-5.5rem;
        z-index: -1;
    }
    .prediciton-banner__wrapper{
        margin-bottom: 1.25rem;
    }
    .prediction-header__right-icon{
        width: 1.25rem;
    }
    .tiktok-wrapper{
        padding-top: 1.5rem;
    }

    .tiktok-icon{
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
    .tiktok-title{
        font-size: 1.375rem;
    }
    .tiktok-desc{
        color: var(--color-grey-light);
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
    }
    .tiktok-video__wrapper{
        width: 760px;
        height: 538px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background-color: #0808087a;
        margin-bottom: 2.3125rem;
        position: relative
    }
    .tiktok-participate__wrapper{
        width: 37.5rem;
        margin: 0 auto;
        border-radius: .5rem;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    .tiktok-participate__header{
        text-align: center;
        padding: 0.6825rem;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 2.3125rem;
        background-color: var( --color-theme);
    }
     .tiktok-participate__body{
        padding: 1.5625rem 2.25rem;
        background-color: var(--color-grey-60);
        font-size: 0.6875rem;
        font-weight: 400;
     }
     .tiktok-tnc__wrapper{
        margin-bottom: 1.375rem;
     }
     .tiktok-bg__cover{
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 90.62%);
        position: absolute;
        width: 100%;
        height: 50%;
        bottom: 0;
    }
    .tiktok-video__img{
        position: absolute;
        top: 0;
        bottom: 0;        
    }
  @media (max-width: 768px){
        .prediciton-banner__wrapper{
            margin-bottom: 1rem;
        }
        .tiktok-video__wrapper{
            width: 93%;
            height: 165vw;
            margin-bottom: 1.5rem;
            background-color: transparent;
        }
        .tiktok-participate__wrapper{
            width: 100%;
        }
        .tiktok-participate__body{
            padding: 0.625rem;
        }
    }

</style>